import { lazy } from "react";
import SalesLeads from "../pages/salesLeads/SalesLeads";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

const Partner = lazy(() => import("../pages/partner/Partner"));
const AddPartner = lazy(() => import("../pages/partner/addPartner/AddPartner"));
const IndividualPartner = lazy(() =>
  import("../pages/individualPartner/IndividualPartner")
);

const Applicants = lazy(() => import("../pages/applicants/Applicants"));
const RejectedApplicants = lazy(() =>
  import("../pages/rejectedApplicants/RejectedApplicants")
);
const FormerPartners = lazy(() =>
  import("../pages/formerPartners/FormerPartners")
);

const Statement = lazy(() => import("../pages/statement/Statement"));
const PaymentRequested = lazy(() =>
  import("../pages/paymentRequested/PaymentRequested")
);
const Admins = lazy(() => import("../pages/admins/Admins"));

const LiveClass = lazy(() => import("../pages/liveClass/LiveClass"));

const CreateLiveClass = lazy(() =>
  import("../pages/liveClass/createLiveClass/CreateLiveClass")
);

const UpdateLiveClass = lazy(() =>
  import("../pages/liveClass/updateLiveClass/UpdateLiveClass")
);

const EnrolledStudents = lazy(() =>
  import("../pages/liveClass/enrolledStudentsTable/EnrolledStudentsTable")
);

const LiveClassTeacher = lazy(() =>
  import("../pages/liveClassTeacher/LiveClassTeacher")
);

const CreateLiveClassTeacher = lazy(() =>
  import(
    "../pages/liveClassTeacher/createLiveClassTeacher/CreateLiveClassTeacher"
  )
);

const UpdateLiveClassTeacher = lazy(() =>
  import(
    "../pages/liveClassTeacher/updateLiveClassTeacher/UpdateLiveClassTeacher"
  )
);

const appRoutes = [
  {
    id: "dashboard",
    path: "/",
    exact: true,
    component: Dashboard,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "affiliate_partners",
    path: "/affiliate/partners",
    exact: true,
    component: Partner,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "new_affiliate_partners",
    path: "/affiliate/partners/new",
    exact: true,
    component: AddPartner,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "new_affiliate_partners",
    path: "/affiliate/partners/:partnerId/dashboard",
    exact: true,
    component: IndividualPartner,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "former_affiliate_partners",
    path: "/affiliate/partners/former/:partnerId/dashboard",
    exact: true,
    component: IndividualPartner,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "affiliate_partners",
    path: "/affiliate/applicants",
    exact: true,
    component: Applicants,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "affiliate_partners",
    path: "/affiliate/former-partners",
    exact: true,
    component: FormerPartners,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "affiliate_partners",
    path: "/affiliate/rejected-applicants",
    exact: true,
    component: RejectedApplicants,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "statement",
    path: "/statement",
    exact: true,
    component: Statement,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "adminUsers",
    path: "/admins",
    exact: true,
    component: Admins,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "salesLeads",
    path: "/sales-leads",
    exact: true,
    component: SalesLeads,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "payment_requested",
    path: "/payment_requested",
    exact: true,
    component: PaymentRequested,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "live-class",
    path: "/live-class",
    exact: true,
    component: LiveClass,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "create-live-class",
    path: "/live-class/new",
    exact: true,
    component: CreateLiveClass,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "update-live-class",
    path: "/live-class/:id/update",
    exact: true,
    component: UpdateLiveClass,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "students",
    path: "/live-class/:id/students",
    exact: true,
    component: EnrolledStudents,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "live-class-teacher",
    path: "/live-class-teacher",
    exact: true,
    component: LiveClassTeacher,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "create-live-class-teacher",
    path: "/live-class-teacher/new",
    exact: true,
    component: CreateLiveClassTeacher,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "update-live-class-teacher",
    path: "/live-class-teacher/:id/update",
    exact: true,
    component: UpdateLiveClassTeacher,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
];

export default appRoutes;
