import React from "react";
import Sidebar from "../components/sidebar/Sidebar";

const AppLayout = ({ children }) => {
  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="sticky top-0 left-0 h-[100vh] w-80 overflow-hidden">
        <Sidebar />
      </div>

      {/* DisplayData */}
      <div className="w-full max-h-[100vh] bg-white overflow-x-hidden overflow-y-scroll p-10">
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
