import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Disclosure, Transition } from "@headlessui/react";
import cn from "classnames";

function Menu({ item }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isActiveNav =
    item?.link === location?.pathname ||
    item?.subMenus?.some((item) => item.link === location?.pathname) ||
    item?.subMenus?.some((item) =>
      item?.subPaths?.includes(location?.pathname)
    ) ||
    item?.subMenus?.some((item) => {
      // return location?.pathname?.match(item?.regex);
      return item?.regex?.some((reg) => location?.pathname?.match(reg));
    });

  const isActiveSubMenu = (subItem) =>
    subItem?.link === location?.pathname ||
    subItem?.subPaths?.includes(location?.pathname) ||
    subItem?.regex?.some((reg) => location?.pathname?.match(reg));

  return (
    <>
      <div className="flex w-full">
        <div className="w-full max-w-md rounded-2xl">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={cn(
                    { "bg-hoverColor": isActiveNav },
                    "flex w-full justify-between items-center rounded-lg px-3 py-3 text-left font-medium hover:bg-hoverColor focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75"
                  )}
                  onClick={() => navigate(item?.link)}
                >
                  <span className="px-2 font-semibold">{item.name}</span>
                  {item.hasSubMenu && (
                    <ChevronDownIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 `}
                    />
                  )}
                </Disclosure.Button>
                <Transition
                  enter="transition-all duration-300 ease-in-out"
                  enterFrom="transform  opacity-0"
                  enterTo="transform  opacity-100"
                  leave="transition-all duration-300 ease-in-out"
                  leaveFrom="transform  opacity-100"
                  leaveTo="transform  opacity-0"
                >
                  {item.hasSubMenu && (
                    <Disclosure.Panel className="mt-1">
                      <div>
                        {item.subMenus.map((subItem) => (
                          <div
                            key={subItem.id}
                            onClick={() => navigate(subItem?.link)}
                            className={cn(
                              {
                                "bg-[#F3F4F6] rounded-lg text-primaryColor":
                                  isActiveSubMenu(subItem),
                              },
                              "text-[14px] py-3 px-[32px] cursor-pointer"
                            )}
                          >
                            {subItem.name}
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  )}
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
}

export default Menu;
