import Modal from "../../../../components/modal/Modal";

const OrderDetailsModal = ({ isOpen, closeModal, selectedOrderData }) => {
  return (
    <div>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className=" w-[30rem] h-[35rem] flex flex-col py-5 px-5 gap-5 text-gray-600 overflow-x-hidden overflow-y-auto">
          <div className="text-2xl font-bold">Purchase Details</div>
          <div className=" p-3">
            <div className=" flex justify-between">
              <h1>Order id</h1>
              <p className="font-bold truncate">{selectedOrderData?._id}</p>
            </div>
            <div className=" flex justify-between">
              <h1>Referal Code</h1>
              <p className="font-bold">
                {selectedOrderData?.referalCode?.code || "-"}
              </p>
            </div>
            <div className=" flex justify-between">
              <h1>Payment Method</h1>
              <p className="font-bold">{selectedOrderData?.payment?.vendor}</p>
            </div>
          </div>
          {selectedOrderData?.items?.length > 0 ? (
            selectedOrderData.items.map((orderDetails) => (
              <>
                <div className=" p-3 border-t-2 border-gray-400">
                  <div className=" flex justify-between">
                    <h1>Course Name</h1>
                    <p className="font-bold">{orderDetails?.course?.name}</p>
                  </div>
                  <div className=" flex justify-between">
                    <h1>Subscription</h1>
                    <p className="font-bold">{orderDetails?.month} Month(s)</p>
                  </div>
                  <div className=" flex justify-between">
                    <h1>Actual Price</h1>
                    <p className="font-bold">Rs.{orderDetails?.price}</p>
                  </div>
                  <div className=" flex justify-between">
                    <h1>Expiry Date</h1>
                    <p className="font-bold">{orderDetails?.expiresAt}</p>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="pt-8 mt-4 text-center">
              No any purchased courses.
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default OrderDetailsModal;
