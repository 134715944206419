import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const Pagination = ({ handlePageClick, prevDisabled, nextDisabled }) => {
  const handlePrev = () => {
    handlePageClick({ isPrevious: true, isNext: false });
  };

  const handleNext = () => {
    handlePageClick({ isPrevious: false, isNext: true });
  };
  return (
    <>
      <div className="pagination">
        <button
          onClick={handlePrev}
          className="bg-primaryColor py-2 px-3 flex items-center gap-2 rounded-md text-white hover:bg-hoverColor disabled:bg-gray-500 disabled:cursor-not-allowed"
          disabled={prevDisabled}
        >
          <ChevronLeftIcon className="w-6 h-6" />
          Previous
        </button>
        <button
          onClick={handleNext}
          className="bg-primaryColor py-2 px-3 flex items-center gap-2 rounded-md text-white hover:bg-hoverColor disabled:bg-gray-500 disabled:cursor-not-allowed"
          disabled={nextDisabled}
        >
          Next
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
    </>
  );
};

export default Pagination;
