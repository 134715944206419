const sidebarData = [
  { id: "dashboard", name: "Dashboard", link: "/" },
  {
    id: "manage_affiliate",
    name: "Manage Affiliate",
    // link: "/affiliate",
    hasSubMenu: true,
    subMenus: [
      {
        id: "partners",
        name: "Partners",
        link: "/affiliate/partners",
        subPaths: ["/affiliate/partners/new"],
        regex: ["partners/[a-zA-z0-9]*/dashboard"],
      },
      { id: "applicants", name: "Applicants", link: "/affiliate/applicants" },
      {
        id: "former_partners",
        name: "Former Partners",
        link: "/affiliate/former-partners",
        regex: ["partners/former/[a-zA-z0-9]*/dashboard"],
      },
      {
        id: "rejected_applicants",
        name: "Rejected Applicants",
        link: "/affiliate/rejected-applicants",
      },
    ],
  },
  { id: "salesLeads", name: "Sales Leads", link: "/sales-leads" },
  { id: "statement", name: "Statement", link: "/statement" },
  { id: "admins", name: "Admin User", link: "/admins" },
  {
    id: "payment_requested ",
    name: "Payment Requested",
    link: "/payment_requested",
  },
  {
    id: "live-class",
    name: "Live Class",
    hasSubMenu: true,
    subMenus: [
      {
        id: "live-class",
        name: "Classes",
        link: "/live-class",
        regex: ["live-class/[a-zA-z0-9]*"],
      },
      {
        id: "live-class-teacher",
        name: "Teachers",
        link: "/live-class-teacher",
        regex: ["live-class-teacher/[a-zA-z0-9]*"],
      },
    ],
  },
];

export default sidebarData;
