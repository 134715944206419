import Router from "./router/Router";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { store } from "./redux/store";

function App() {
  return (
    <>
      <Provider store={store}>
        <Router />
        <Toaster
          position="top-center"
          toastOptions={{
            error: {
              duration: 3000,
            },
          }}
        />
      </Provider>
    </>
  );
}

export default App;
