import http from "../../utility/http";

const getSalesLeadsApi = async ({ page, count }) => {
  const response = await http.get(`/order`, {
    params: { page, count },
  });
  return response?.data;
};

export default getSalesLeadsApi;
