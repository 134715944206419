import { useEffect, useState } from "react";
import SearchAffiliate from "../../components/searchBar/searchAffiliate/SearchAffiliate";
import getSalesLeadsApi from "../../services/salesLeads/getSalesLeads";
import SalesLeadsTable from "./salesLeadsTable/SalesLeadsTable";
import Pagination from "../../components/pagination/Pagination";
import OrderDetailsModal from "../../pages/individualPartner/partnerSales/saleModal/OrderDetailsModal";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

const COUNT = 20;

const SalesLeads = () => {
  const [salesData, setSalesData] = useState([]);

  const [selectedData, setSelectedData] = useState({});
  const [isDetailModal, setIsDetailModal] = useState(false);
  const [page, setPage] = useState(1);
  const [prevCursor, setPrevCursor] = useState(null);
  const [nextCursor, setNextCursor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSalesData();
  }, [page]);

  async function getSalesData() {
    try {
      setIsLoading(true);
      const data = await getSalesLeadsApi({ page, count: COUNT });
      setSalesData(data?.docs);
      setNextCursor(data?.hasNextPage);
      setPrevCursor(data?.hasPrevPage);
      setIsLoading(false);
    } catch (e) {}
  }

  function handlePageClick({ isNext, isPrevious }) {
    if (isNext) {
      setPage((prev) => prev + 1);
    }
    if (isPrevious) {
      setPage((prev) => prev - 1);
    }
  }

  const closeDetailModal = () => setIsDetailModal(false);

  return (
    <div>
      <h5 className="heading5">Sales Leads</h5>
      <SearchAffiliate />

      {isLoading ? (
        <div className="mt-10">
          <TableSkeleton count={20} className={"mt-5"} />
        </div>
      ) : (
        <>
          <SalesLeadsTable
            data={salesData}
            setSelectedData={setSelectedData}
            setIsDetailModal={setIsDetailModal}
            page={page}
            count={COUNT}
          />
          <Pagination
            handlePageClick={handlePageClick}
            prevDisabled={!prevCursor}
            nextDisabled={!nextCursor}
          />
        </>
      )}
      <OrderDetailsModal
        isOpen={isDetailModal}
        closeModal={closeDetailModal}
        selectedOrderData={selectedData}
      />
    </div>
  );
};

export default SalesLeads;
