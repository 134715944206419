import moment from "moment";

const tableHeader = [
  {
    id: 1,
    name: "S.N",
  },

  {
    id: 2,
    name: "User",
  },
  {
    id: 3,
    name: "Date",
  },
  {
    id: 4,
    name: "Course",
  },
  {
    id: 5,
    name: "Affiliate Code",
  },
  {
    id: 6,
    name: "Paid Amount",
  },
];

const SalesLeadsTable = ({
  data,
  setSelectedData,
  setIsDetailModal,
  count,
  page,
}) => {
  return (
    <div className="w-full mt-6">
      <table className="table-auto w-full">
        <thead>
          <tr className="">
            {tableHeader.map((head) => {
              return (
                <th className="tableHead" key={head.id}>
                  {head.name}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {data.length ? (
            data.map((item, index) => {
              console.log(moment(item?.createdAt).format("MM DD, yyyy"));

              return (
                <tr
                  key={index}
                  className="[&>td]:tableData border-y-[1px]"
                  onClick={() => {
                    setSelectedData(item);
                    setIsDetailModal(true);
                  }}
                >
                  <td>{(page - 1) * count + index + 1}</td>
                  <td>{item?.user?.name}</td>
                  <td>{moment(item?.createdAt).format("DD MMM, yyyy")}</td>
                  <td>
                    {item?.items[0]?.course?.name}
                    {item?.items?.length > 1 && (
                      <span> +{item?.items?.length - 1 + "more"} </span>
                    )}
                  </td>
                  <td>{item?.affiliateCode}</td>
                  <td>{item?.paidAmount}</td>
                  <td>{item?.amount}</td>
                </tr>
              );
            })
          ) : (
            <tr className="mt-2 font-medium text-sm">
              <td> No Data to show</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SalesLeadsTable;
