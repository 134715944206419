import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const SearchAffiliate = () => {
  return (
    <form className="flex items-center mt-8 p-4 rounded-md border border-gray-300">
      <MagnifyingGlassIcon className="h-6" />
      <input
        type="text"
        className="px-4 w-full outline-none"
        placeholder="Search Affiliate"
      />
    </form>
  );
};

export default SearchAffiliate;
