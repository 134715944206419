const TableSkeleton = ({ count = 5, className }) => {
  return (
    <div role="status" class="animate-pulse">
      {Array.from({ length: count }).map((it, ind) => (
        <div
          key={ind}
          className={`mx-5 mb-2.5 h-4 w-full rounded-xl bg-gray-200 dark:bg-gray-400 ${className}`}
        ></div>
      ))}
    </div>
  );
};

export default TableSkeleton;
